<!-- 交易商品详情 -->
<template>
  <div class="trading-details content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-if="tradingType === 'license'" :to="{ path: '/trading/license' }">开放许可</el-breadcrumb-item>
      <el-breadcrumb-item v-else :to="{ path: tradingTypeObj.url }">{{ tradingTypeObj.name }}交易</el-breadcrumb-item>
      <el-breadcrumb-item>{{ tradingTypeObj.name }}详情</el-breadcrumb-item>
    </el-breadcrumb>
    <section class="basic-info back-F6F7FB mb-30">
      <h3 class="info-title mb-30">{{ product_info.name }}</h3>
      <div class="activity-basic flex">
        <el-image class="info-img border-r4 mr-30" :src="$base + product_info.path" fit="contain">
          <div slot="error" style="min-width:200px" class="image-slot">
            <el-image :src="require('@/assets/images/trading-default.png')"></el-image>
          </div>
        </el-image>
        <div class="flex-1 flex-column justify-between">
          <el-descriptions v-if="tradingType != 'trademark'" :column="2" :colon="false" labelClassName="info-label"
            contentClassName="info-content">
            <el-descriptions-item label="专利号">{{ product_info.ano }}</el-descriptions-item>
            <el-descriptions-item label="专利权人">{{ product_info.patentee }}</el-descriptions-item>
            <el-descriptions-item label="专利类型">{{ product_info.patent_type }}</el-descriptions-item>
            <el-descriptions-item label="领域类型">{{ product_info.domain_type }}</el-descriptions-item>
            <el-descriptions-item label="有效日期">{{ product_info.expired_at }}</el-descriptions-item>
            <el-descriptions-item label="法律状态">{{ product_info.law_state }}</el-descriptions-item>
            <el-descriptions-item label="合作类型">{{ product_info.feat_type }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions v-else :column="2" :colon="false" labelClassName="info-label" contentClassName="info-content">
            <el-descriptions-item label="注册号">{{ product_info.ano }}</el-descriptions-item>
            <el-descriptions-item label="国际分类"> {{ product_info.domain_type }}</el-descriptions-item>
            <el-descriptions-item label="类似群组"> {{ product_info.group }}</el-descriptions-item>
            <el-descriptions-item label="有效日期">{{ product_info.expired_at }}</el-descriptions-item>
            <el-descriptions-item label="商标状态"> {{ product_info.law_state }}</el-descriptions-item>
            <el-descriptions-item label="合作类型"> {{ product_info.feat_type }}</el-descriptions-item>
          </el-descriptions>
          <p v-if="tradingType == 'license'" class="align-center mt-30">
            <span> 咨询电话:</span>
            <span class="ml-20" style="font-size: 24px;color: #409EFF;">{{ this.$store.getters.configs.hotline_phone
            }}</span>
          </p>
          <div v-else class="align-center mt-30">
            <p class="align-center mr-30">
              <span> 价格:</span>
              <span class="ml-20" style="font-size: 24px;color: #409EFF;">{{ product_info.price }}</span>
            </p>
            <el-button type="primary" size="small" round @click="submitOrder">立即下单</el-button>
          </div>
        </div>
      </div>
    </section>
    <section class="details-tabs back-F6F7FB mb-30">
      <ul ref="tabsNav" :class="['tabs-nav', 'back-F6F7FB', 'flex', 'pa-20', { 'fixed': tabsNavFixed }]"
        :style="{ 'width': `${tabsWidth - 40}px` }">
        <li :class="['tab-nav-item', { 'active': activeTabInd === index }]"
          v-for="(item, index) in $data[tradingType + '_tabs']" :key="index" @click="changeTab(index)">
          {{ item.label }}
        </li>
      </ul>
      <div class="tabs-content" :style="{ 'paddingTop': `${tabsNavFixed ? 57 : 0}px` }">
        <div v-if="tradingType == 'license'" class="tabs-content-item">
          <h2 class="mb-20">在先实施和许可情况</h2>
          <el-table :data="[product_info]" class="mb-30" border>
            <el-table-column prop="product_name" label="自行实施情况">
              <template slot-scope="scope">
                {{ scope.row.carry_out_state == 1 ? '有实施' : '未实施' }}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="在先许可情况">
              <template slot-scope="scope">
                {{ scope.row.carry_out_state == 1 ? '有' : '无' }}
              </template>
            </el-table-column>
          </el-table>
          <h2 class="mb-20">本次许可情况</h2>
          <el-table :data="[product_info]" border>
            <el-table-column prop="premission_at" label="许可期限届满日"> </el-table-column>
            <el-table-column prop="price" label="费用类型"> </el-table-column>
            <el-table-column prop="price_details" label="费用详情"> </el-table-column>
            <el-table-column prop="other_details" label="其它约定事项"> </el-table-column>
          </el-table>
        </div>
        <div class="tabs-content-item">
          <h2 class="mb-20">{{ tradingType != 'trademark' ? '摘要' : '服务项目' }}</h2>
          <p>{{ product_info.details }}</p>
        </div>
        <template v-if="tradingType != 'license'">
          <div class="tabs-content-item">
            <el-image :src="require('@/assets/images/trading-img1.png')"></el-image>
          </div>
          <div class="tabs-content-item">
            <el-image :src="require('@/assets/images/trading-img2.png')"></el-image>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>
<script>
import * as TradingApi from '@/api/trading'
export default {
  name: 'TradingDetails',
  data () {
    return {
      tradingType: 'license',
      activeTabInd: 0,
      license_tabs: [
        { label: '许可情况', name: 'xkqk' },
        { label: '专利详情', name: 'zlqk' }
      ],
      patent_tabs: [
        { label: '专利详情', name: 'zlqk' },
        { label: '过户资料', name: 'ghzl' },
        { label: '安全保证', name: 'aqbz' }
      ],
      trademark_tabs: [
        { label: '商标详情', name: 'sbqk' },
        { label: '过户资料', name: 'ghzl' },
        { label: '安全保证', name: 'aqbz' }
      ],
      tabsNavFixed: false,
      tabsNavTop: 0,
      tabsWidth: 0,
      product_info: {}
    }
  },
  computed: {
    tradingTypeObj () {
      let obj = { name: '', url: '' }
      if (this.tradingType == 'patent') {
        obj.name = '专利'
        obj.url = '/trading/patent'
        obj.type = "Patent"
      } else if (this.tradingType == 'trademark') {
        obj.name = '商标'
        obj.url = '/trading/trademark'
        obj.type = "Trademark"
      } else if (this.tradingType == 'license') {
        obj.name = '许可'
        obj.url = '/trading/license'
      }
      return obj
    }
  },
  mounted () {
    this.tradingType = this.$route.query.type
    this.activeTab = this.$data[this.tradingType + '_tabs'][0].name
    this.fetchTradingProduc()
    setTimeout(() => {
      window.addEventListener('scroll', this.fixedActiveBtn)
      this.tabsNavTop = this.$refs.tabsNav.offsetTop
      this.tabsWidth = document.getElementsByClassName('details-tabs')[0].offsetWidth;
    }, 400);
  },
  methods: {
    // 获取商品详情
    async fetchTradingProduc () {
      try {
        if (this.tradingType == 'patent') {
          var { data } = await TradingApi.fetchPatentProduc(this.$route.query.id)
        } else if (this.tradingType == 'trademark') {
          var { data } = await TradingApi.fetchTrademarkProduc(this.$route.query.id)
        } else if (this.tradingType == 'license') {
          var { data } = await TradingApi.fetchLicenseProduc(this.$route.query.id)
        }
        data['price'] = data['price'] == 0 ? '面议' : data['price']
        this.product_info = data
      } catch (error) { }
    },

    fixedActiveBtn () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
      this.tabsNavFixed = scrollTop > this.tabsNavTop - 100
      let domArr = document.querySelectorAll(".tabs-content-item")
      domArr.forEach((item, i) => {
        let contentItemTop = item.offsetTop
        if (contentItemTop - scrollTop <= 157) {
          this.activeTabInd = i
        }
      })
    },

    submitOrder () {
      this.$confirm('确定下单吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(async () => {
        let queryForm = {
          type: this.tradingTypeObj.type,
          id: this.$route.query.id
        }
        const { code, msg } = await TradingApi.assetOrder(queryForm)
        if (code == 200) {
          this.$message.success('下单成功');
          this.$router.replace('/my/order/trading')
        } else {
          this.$message.error(msg);
        }
      }).catch(() => { });
    },

    changeTab (ind) {
      this.activeTabInd = ind
      document.documentElement.scrollTop = document.querySelectorAll(".tabs-content-item")[ind].offsetTop - 157
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .basic-info {
  padding: 40px;

  .info-title {
    font-size: 24px;
  }

  .el-descriptions__body {
    background-color: transparent;
  }

  .info-label {
    font-size: 16px;
  }

  .info-content {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
}

.details-tabs {
  .tabs-nav {
    border-bottom: 1px solid rgb(204, 204, 204);

    &.fixed {
      position: fixed;
      top: 100px;
      z-index: 1;
    }

    .tab-nav-item {
      margin-right: 20px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: #409EFF;
      }
    }
  }
}

.tabs-content {

  .tabs-content-item {
    max-width: 780px;
    margin: 0 auto;
    padding: 30px 0;

    h2 {
      font-size: 20px;
      text-align: center;

    }

    p {
      text-indent: 18px;
      font-size: 18px;
      line-height: 26px;
      color: #616161;
    }
  }
}
</style>